import { datadogRum, RumInitConfiguration } from "@datadog/browser-rum";

const DATA_DOG_RUM_INIT_CONFIGURATION_RELEASE: RumInitConfiguration = {
  applicationId: "057e3af6-d2b4-4a09-af7b-461932d2603c",
  clientToken: "pub76533eb0bbfb949477570806d12e78a5",
  // `site` refers to the Datadog site parameter of your organization
  // see https://docs.datadoghq.com/getting_started/site/
  site: "us3.datadoghq.com",
  service: "export-module-client",
  env: "release",
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
  trackSessionAcrossSubdomains: true,
  allowFallbackToLocalStorage: true,
};
const DATA_DOG_RUM_INIT_CONFIGURATION_QA: RumInitConfiguration = {
  applicationId: "057e3af6-d2b4-4a09-af7b-461932d2603c",
  clientToken: "pub76533eb0bbfb949477570806d12e78a5",
  // `site` refers to the Datadog site parameter of your organization
  // see https://docs.datadoghq.com/getting_started/site/
  site: "us3.datadoghq.com",
  service: "export-module-client",
  env: "qa",
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
  trackSessionAcrossSubdomains: true,
  allowFallbackToLocalStorage: true,
};

const DATA_DOG_RUM_INIT_CONFIGURATION_DEV: RumInitConfiguration = {
  applicationId: "057e3af6-d2b4-4a09-af7b-461932d2603c",
  clientToken: "pub76533eb0bbfb949477570806d12e78a5",
  // `site` refers to the Datadog site parameter of your organization
  // see https://docs.datadoghq.com/getting_started/site/
  site: "us3.datadoghq.com",
  service: "export-module-client",
  env: "dev",
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
  trackSessionAcrossSubdomains: true,
  allowFallbackToLocalStorage: true,
};

export const dataDogRumInit = () => {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case "prod": {
      datadogRum.init(DATA_DOG_RUM_INIT_CONFIGURATION_RELEASE);
      datadogRum.startSessionReplayRecording();
      break;
    }
    case "qa": {
      datadogRum.init(DATA_DOG_RUM_INIT_CONFIGURATION_QA);
      datadogRum.startSessionReplayRecording();
      break;
    }
    default:
      break;
  }
};

export const setDataDogUser = (user: {
  name: string;
  email: string;
  tenant: string;
}) => {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case "prod":
    case "qa": {
      datadogRum.setUser(user);
      break;
    }
    default:
      break;
  }
};
