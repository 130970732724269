import { useEffect } from "react";
import AsyncConfirmDialogProvider from "./Compositions/AsyncConfirmDialog/AsyncConfirmDialogProvider";
import ToastProvider from "./Compositions/ProgressToast/ToastProvider";
import { Dialog } from "./Core/Layouts/Dialog";
import Routes from "./Routes";
import { useActionLog } from "./useActionLog";
import { dataDogRumInit } from "./datadogRumConfiguration";
import { useDatadog } from "./useDatadog";

dataDogRumInit();

function App() {
  const actionLog = useActionLog();

  useDatadog();

  useEffect(() => {
    actionLog.preExport.clickPreExportButton();
  }, [actionLog]);
  return (
    <>
      <ToastProvider />
      <AsyncConfirmDialogProvider />
      <Dialog>
        <Routes />
      </Dialog>
    </>
  );
}

export default App;
