import { useErrorBoundary } from "react-error-boundary";
import { useUserQuery } from "../useActionLog";
import ImagoEvent from "@imago-cloud/action-log";
import { useMessage } from "../Core/Provider/MessageProvider";

export const useShowErrorBoundary = () => {
  const { showBoundary } = useErrorBoundary();
  const { data: userQueryData } = useUserQuery();
  const {
    message: { designCaseIds },
  } = useMessage();
  const showErrorBoundary = async (err: any) => {
    await ImagoEvent.error.errorPageAppeared({
      isImagoworks: userQueryData?.email?.includes("@imagoworks.ai"),
      tenantId: userQueryData?.tenantId,
      userId: userQueryData?.id,
      designCaseId: designCaseIds[0] || "",
      designSessionId: designCaseIds[0] || "",
      ErrorMessage: "an unknown eroor has occurred",
    });
    showBoundary(err);
  };
  return { showErrorBoundary };
};
