import { Cicon, theme, Typography } from "@imago-cloud/design-system";

import { Stack } from "@mui/material";
import {
  Dialog,
  DialogContent,
  DialogPaper,
  DialogTitle,
} from "../../Core/Layouts/Dialog";
import { onCloseApp } from "../../Core/Utils/postMessage";
import i18next from "i18next";
import { useActionLog } from "../../useActionLog";
import { useEffect } from "react";

export const CannotBeFoundPage = () => {
  const actionLog = useActionLog();
  useEffect(() => {
    actionLog.error.errorPageAppreared({
      ErrorMessage: "case or design cannot be found",
    });
  }, [actionLog]);
  return (
    <Dialog>
      <DialogPaper sx={{ width: "880px", height: "640px" }}>
        <DialogTitle onClose={onCloseApp} isClose />
        <DialogContent>
          <Stack
            sx={{
              width: "100%",
              height: "100%",
              paddingTop: "181px",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Stack
              sx={{
                width: "640px",
                justifyContent: "center",
                alignItems: "center",
                gap: "8px",
                textAlign: "center",
              }}
            >
              <Cicon
                icon="Error"
                svgProps={{ width: "36px", height: "36px" }}
              />
              <Typography variant="H24">
                {i18next.t("case_or_design_cannot_be_found_dialog.title")}
              </Typography>
              <Typography variant="Body16" color={theme.palette.text.secondary}>
                {i18next.t("case_or_design_cannot_be_found_dialog.body")}
              </Typography>
            </Stack>
          </Stack>
        </DialogContent>
      </DialogPaper>
    </Dialog>
  );
};
