import { useQuery } from "@tanstack/react-query";
import { axios } from "./Core/Axios/axiosInstance";
import ImagoEvent from "@imago-cloud/action-log";
import { useMessage } from "./Core/Provider/MessageProvider";
import { useFormContext } from "react-hook-form";
import { useListQuery } from "./Modules/ExportList/Hooks/ReactQueries/useListQuery";
import usePrice from "./Modules/CreditBalance/Hooks/usePrice";
import useList from "./Hooks/Core/useList";
import { USERS_ME_PAI } from "./Constants/api";

export const useUserQuery = () => {
  return useQuery(
    ["user-query"],
    () => axios.get(USERS_ME_PAI).then(({ data: res }) => res.data),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: 10000,
      cacheTime: 10000,
    }
  );
};

export const useActionLog = () => {
  const { creditBalance, totalPrice, isLoading: isPriceLoading } = usePrice();
  const { data: userQueryData, isLoading: isUserQueryLoading } = useUserQuery();
  const { data: listQueryData, isLoading: isListQueryLoading } = useListQuery();
  const { watch } = useFormContext();
  const { getAllListsItems } = useList();
  const {
    message: { exactHost },
  } = useMessage();

  const getProductInfo = () => {
    const itemIds = watch("itemIds") || [];
    return getAllListsItems()
      .filter(({ itemId }) => itemIds.includes(itemId))
      .map(({ itemLabel, priceInfo, itemId }) => ({
        productId: itemId,
        productName: itemLabel.productDisplayType || "",
        toothNumber: itemLabel.toothNumberList || "",
        prosthesis: (itemLabel.productDisplayType === "BRIDGE"
          ? "bridge"
          : "crown") as "bridge" | "crown",
        price: priceInfo?.currentPrice || 0,
        isPaid: priceInfo?.isPaid || false,
        generatingMethod: undefined,
      }));
  };

  const getButtonPosition = () => {
    const referrer = document.referrer || "";
    if (referrer.includes("module-viewer")) return "cloudviewer";
    else return "";
  };
  const isFetchLoading =
    isPriceLoading || isUserQueryLoading || isListQueryLoading;

  const field = {
    isImagoworks: userQueryData?.email?.includes("@imagoworks.ai"),
    tenantId: userQueryData?.tenantId,
    userId: userQueryData?.id,
    freeCreditAmount: creditBalance.freeCredits || 0,
    totalCredits: totalPrice,
    balanceCredits: creditBalance.balance || 0,
    caseTotalSize: 0,
    discountCredits: 0,
    caseName: listQueryData?.designCaseList[0].designCaseName || "",
    designCaseId: listQueryData?.designCaseList[0].designCaseId || "",
    designSessionId: listQueryData?.designCaseList[0].designCaseId || "",
    isBatch: exactHost === "batch",
    paidCreditAmount: creditBalance.paidCredits || 0,
    productInfo: getProductInfo(),
    buttonPosition:
      getButtonPosition() as keyof typeof ImagoEvent.preExport.clickPreExportButton,
    isInstantMode: false,
  };

  return {
    preExport: {
      clickPreExportButton: () => {
        if (isFetchLoading) return;
        ImagoEvent.preExport.clickPreExportButton({ ...field });
      },
    },
    noCredit: {
      shoNoCreditAlert: () => {
        if (isFetchLoading) return;
        ImagoEvent.noCredit.showNoCreditAlert({
          ...field,
        });
      },
    },
    export: {
      clickExportButtonInModal: () => {
        if (isFetchLoading) return;
        ImagoEvent.export.clickExportButtonInModal({
          ...field,
        });
      },

      clickExportToSoftware: () => {
        if (isFetchLoading) return;
        ImagoEvent.export.clickExportToSoftware({ ...field });
      },

      selectSoftware: ({ selectedSoftware }: { selectedSoftware: any }) => {
        if (isFetchLoading) return;
        ImagoEvent.export.selectSoftware({ ...field, selectedSoftware });
      },

      downloadExportedFile: () => {
        if (isFetchLoading) return;
        ImagoEvent.export.downloadExportedFile({ ...field });
      },
    },
    error: {
      errorPageAppreared: ({ ErrorMessage }: { ErrorMessage: string }) => {
        ImagoEvent.error.errorPageAppeared({ ...field, ErrorMessage });
      },
    },
  };
};
