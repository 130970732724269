import { useUserQuery } from "./useActionLog";
import { setDataDogUser } from "./datadogRumConfiguration";
import { useEffect } from "react";

export const useDatadog = () => {
  const { data: userData } = useUserQuery();
  const user = userData;

  useEffect(() => {
    if (user) {
      setDataDogUser({
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
        tenant: user.tenantId,
      });
    }
  }, [user]);
};
