import { useFormContext } from "react-hook-form";

import { useCreditBalanceQuery } from "./ReactQueries/useCreditBalanceQuery";
import useList from "../../../Hooks/Core/useList";
import { PAYMENT_URL } from "../../../config";

const usePrice = () => {
  const { data: creditBalanceData, isLoading } = useCreditBalanceQuery();
  const { balance } = creditBalanceData || { balance: 0 };
  const { watch } = useFormContext();
  const { getAllListsItems } = useList();
  const itemIds = watch("itemIds") || [];
  const totalPrice = getAllListsItems()
    .filter(({ itemId }) => itemIds.includes(itemId))
    .filter(
      ({ isPaymentRequired, priceInfo }) =>
        isPaymentRequired && !priceInfo?.isPaid
    )
    .reduce((acc, { priceInfo }) => {
      return acc + (priceInfo?.currentPrice || 0);
    }, 0);

  const isEnoughCredit = balance >= totalPrice;

  const onPaymentWindowOpen = () => {
    const width = 680;
    const height = 770;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;
    const features = `width=${width},height=${height},top=${top},left=${left}`;
    window.open(PAYMENT_URL, "_blank", features);
  };

  return {
    totalPrice,
    creditBalance: { ...creditBalanceData },
    isEnoughCredit,
    isLoading,
    onPaymentWindowOpen,
  };
};

export default usePrice;
