import {
  theme as ITheme,
  ThemeProvider as IThemeProvider,
} from "@imago-cloud/design-system";
import { createTheme } from "@mui/material";

export const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const Theme = () => {
    return createTheme({
      ...ITheme,
      components: {
        ...ITheme.components,
        MuiInputBase: {
          defaultProps: {
            autoComplete: "off",
          },
        },
        MuiDialog: {
          defaultProps: {
            TransitionProps: {
              onEnter: (node) => {
                document
                  .querySelectorAll<HTMLElement>(
                    ".MuiDialog-root .MuiBackdrop-root"
                  )
                  .forEach((backdrop) => {
                    backdrop.style.opacity = "0";
                  });
                const currentBackdrop =
                  node.querySelector<HTMLElement>(".MuiBackdrop-root");
                if (currentBackdrop) {
                  currentBackdrop.style.opacity = "1";
                }
              },
              onExit: () => {
                const dialogs = Array.from(
                  document.querySelectorAll(".MuiDialog-root")
                );
                if (dialogs.length > 1) {
                  const lastDialog = dialogs[dialogs.length - 2]; // 자신을 제외한 다음 다이얼로그
                  const backdrop =
                    lastDialog.querySelector<HTMLElement>(".MuiBackdrop-root");
                  if (backdrop) {
                    backdrop.style.opacity = "1";
                  }
                }
              },
            },
          },
        },
      },
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 1280,
          lg: 1920,
          xl: 2560,
        },
      },
    });
  };
  return <IThemeProvider theme={Theme()}>{children}</IThemeProvider>;
};
